import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div id="page" className="section">
      <Container>
        <Row>
          <Col sm="12" className="text-center">
            <h1 className="display-1">Errore 404</h1>
            <h2 className="font-family-base">Oooops! Pagina non trovata.</h2>
            <p>
La pagina che stai cercando non esiste.
              {' '}
              <br />
              {' '}
Torna alla
              {' '}
              <Link to="/">home page</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

export default NotFoundPage;
